/* -------------------------------------------------------------------------- */


/*                  VARIABLES (se pueden modificar desde ts)                  */


/* -------------------------------------------------------------------------- */

:root {
    --posicionFinal: -100px;
    --posicionInicial: 0px;
    --tiempo: 2s;
}


/* -------------------------------------------------------------------------- */


/*                                FIN VARIABLES                               */


/* -------------------------------------------------------------------------- */


.inline {
    display: inline-block;
}

.bordeContenedor {
  border-radius: 30px;
  /* box-shadow: 2px 2px 2px black; */
  box-shadow: 2px 2px 2px grey;
  /* min-height: 500px; */
  /* border: 1px solid black; */
}

.bordeContenedorRojo {
  border-radius: 50px;
  box-shadow: 2px 2px 2px rgb(240, 12, 12);
}



.redondeado {
  border-radius: 15px;

}

.encima {
    z-index: 2;
}

.debajo {
    z-index: 0;
}

.mensaje {
    display: scroll;
    position: fixed;
    bottom: 50%;
    width: 100%;
    z-index: 2;
}

.buscador {
    display: scroll;
    position: fixed;
    top: 10%;
    width: 80%;
    margin-left: 10%;
    padding: 10px;
    background-color: rgb(255, 229, 173);
    border: 1px solid #165480;
    height: 70%;
    border-radius: 20px;

}

.borde {
    border: 1px solid #0a0a0a;
}

.bordeAbajo {
    border-bottom: 1px solid #0a0a0a;
}

.galeriaImagenes {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}

.imagenesSubir {
    width: 200px;
    height: 200px;
    padding: 10px;
}

.fuenteMuyPequena {
    font-size: 0.4rem;
}

.fuentePequena {
    font-size: 0.5rem;
}

.fuenteMuyReducida {
    font-size: 0.7rem;
}

.fuenteReducida {
    font-size: 0.8rem;
}

.fuenteAjustada {
    font-size: 0.85rem;
}

.fuenteMedia {
    font-size: 1.25rem;
}

.fuenteAumentada {
    font-size: 1.5rem;
}

.fuenteGrande {
    font-size: 1.75rem;
}

.fuenteMuyGrande {
    font-size: 2.25rem;
}

.botonera {
    /* float: right;  */
    margin-left: 60%;
    position: relative;
}

.botonera-flotante {
    display: scroll;
    position: fixed;
    top: 100px;
    right: 100px;
}

.centrado {
    display: flex;
    justify-content: center;
}

.textoJustificado {
    text-align: justify;
}

.textoCentrado {
    text-align: center;
}




/* -------------------------------------------------------------------------- */


/*                                   COLORES                                  */


/* -------------------------------------------------------------------------- */

.azul {
    color: rgb(1, 1, 255) !important;
}

.blanco {
    color: rgb(255, 255, 255) !important;
}

.rojo {
    color: rgb(255, 0, 0) !important;
}

.verde {
    color: rgb(8, 117, 8) !important;
}

.fondoAzul {
    background-color: rgb(0, 0, 255);
  }

.fondoBlanco {
    background-color: white;
  }

.fondoNaranja {
    background-color: rgb(243, 150, 10);
  }
  
.fondoMorado {
    background-color: rgb(153, 8, 153);
}

.fondoVerde {
    background-color: rgb(7, 160, 53);
}

.fondoGris {
    background-color: grey;
}

.fondoRojo {
    background-color: rgb(206, 27, 27);
}

.fondoBuscador {
    background-color: rgb(255, 229, 173);
}




/* -------------------------------------------------------------------------- */


/*                                  MARGENES                                  */


/* -------------------------------------------------------------------------- */

.mb3px {
    margin-bottom: 3px;
}

.mb10px {
    margin-bottom: 10px;
}

.ml3px {
    margin-left: 3px;
}

.ml5px {
    margin-left: 5px;
}

.ml13px {
    margin-left: 13px;
}

.ml30px {
    margin-left: 30px;
}

.ml40px {
    margin-left: 40px;
}

.ml-3px {
    margin-left: -3px;
}

.ml-13px {
    margin-left: -13px;
}

.mr5px {
    margin-right: 5px;
}

.mr-10px {
    margin-right: -10px;
}

.mt5px {
    margin-top: 5px;
}

.mt-5px {
    margin-top: -5px;
}

.mt-7px {
    margin-top: -7px;
}

.mt-10px {
    margin-top: -10px;
}

.mt-15px {
    margin-top: -15px;
}

.mt-20px {
    margin-top: -20px;
}

.mt-30px {
    margin-top: -30px;
}


/* -------------------------------------------------------------------------- */


/*                                   FONDOS                                   */


/* -------------------------------------------------------------------------- */

.fondoAcordeon {
    background-color: rgb(242, 242, 242);
    color: rgb(10, 10, 10);
}

.fondoFormulario {
    background-color: rgb(242, 242, 242);
    color: rgb(10, 10, 10);
    padding: 3%;
}

.fondoLineas {
    background-color: rgb(198, 203, 245);
    color: rgb(10, 10, 10);
    padding: 1%;
}

.fondoBadge {
    background-color: rgb(242, 242, 242);
    color: rgb(10, 10, 10);
}

.fondoBuscador {
    background-color: rgb(255, 229, 173);
}


/* -------------------------------------------------------------------------- */


/*                            CAMPOS REPORT INICIO                            */


/* -------------------------------------------------------------------------- */

.anchoA4 {
    width: 210mm;
}

.altoA4 {
    height: 300mm;
}

.anchoA4Apaisado {
    width: 300mm;
}

.altoA4Apaisado {
    height: 200mm;
}

.cr05 {
    width: 5%;
}

.cr1 {
    width: 10%;
}

.cr1y5 {
    width: 15%;
}

.cr2 {
    width: 20%;
}

.cr25 {
    width: 25%;
}

.cr3 {
    width: 30%;
}

.cr35 {
    width: 35%;
}

.cr4 {
    width: 40%;
}

.cr45 {
    width: 45%;
}

.cr5 {
    width: 50%;
}

.cr55 {
    width: 55%;
}

.cr6 {
    width: 60%;
}

.cr7 {
    width: 70%;
}

.cr75 {
    width: 75%;
}

.cr8 {
    width: 80%;
}

.cr9 {
    width: 90%;
}

.crml2 {
    margin-left: 2%;
}

.crml-2 {
    margin-left: -2%;
}

.crml3 {
    margin-left: 3%;
}

.crml-3 {
    margin-left: -3%;
}

.crml4 {
    margin-left: 4%;
}

.crml-4 {
    margin-left: -4%;
}

.crml10 {
    margin-left: 10%;
}

.crmt2 {
    margin-top: 2%;
}

.crmt10 {
    margin-top: 10%;
}

.crmr2 {
    margin-right: 2%;
}

.crSombreado {
    background-color: rgb(240, 238, 233);
    border: 1px solid #0a0a0a;
}

.fuenteReport {
    font-size: 10pt;
}


/* -------------------------------------------------------------------------- */


/*                              CAMPOS REPORT FIN                             */


/* -------------------------------------------------------------------------- */

.formulario-flotante {
    display: scroll;
    position: fixed;
    bottom: 50%;
    left: 10%;
    width: 80%;
    padding: 10px;
    background-color: rgb(255, 229, 173);
    border: 2px solid #165480;
}

.centro-flotante {
    display: scroll;
    position: fixed;
    bottom: 50%;
    right: 50%;
}

.centroIzquierda-flotante {
    display: scroll;
    position: fixed;
    bottom: 50%;
    left: 10px;
}

.arriba-flotante {
    display: scroll;
    position: fixed;
    top: 0px;
}

.arribaDerecha-flotante {
    display: scroll;
    position: fixed;
    top: 100px;
    right: 100px;
}

.arribaIzquierda-flotante {
    display: scroll;
    position: fixed;
    top: 100px;
    left: 100px;
}

.arribaCentro-flotante {
    display: scroll;
    position: fixed;
    top: 100px;
    left: 40%;
}

.abajo-flotante {
    display: scroll;
    position: fixed;
    bottom: 0px;
}

.abajoDerecha-flotante {
    display: scroll;
    position: fixed;
    bottom: 100px;
    right: 100px;
}

.abajoIzquierda-flotante {
    display: scroll;
    position: fixed;
    bottom: 100px;
    left: 100px;
}

.traerAlFrente {
    z-index: 100;
}

.traerDelante {
    z-index: 50;
}

.oculto {
    display: none;
}

.clickable {
    cursor: pointer;
}

.anchoCompleto {
    width: 100%;
}

.boton-nuevo {
    background-color: rgb(41, 231, 89);
}

.lineaNumero {
    text-align: right;
}


/* -------------------------------------------------------------------------- */


/*                                 IMPRESIONES                                */


/* -------------------------------------------------------------------------- */

.pagebreak {
    clear: both;
    page-break-after: always;
}


/* -------------------------------------------------------------------------- */


/*                                 CALCULADORA                                */


/* -------------------------------------------------------------------------- */

.botonNumeroCalculadora {
    width: 31%;
    height: 70px;
    margin-bottom: 3px;
    margin-left: 3px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    font-size: 24pt;
}

.botonFuncionCalculadora {
    width: 100%;
    margin-bottom: 3px;
    height: 70px;
    font-size: 24pt;
}

.botonEnterCalculadora {
    margin-top: 3px;
    width: 100%;
    height: 140px;
    vertical-align: bottom;
}

.seccionCalculadora {
    width: 75%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}

.seccion2Calculadora {
    width: 25%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}

/* -------------------------------------------------------------------------- */


/*                                 MONEDERO                                */


/* -------------------------------------------------------------------------- */

.botonNumeroMonedero {
    width: 20%;
    height: 70px;
    margin-bottom: 3px;
    margin-left: 3px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    font-size: 24pt;
}

.botonFuncionMonedero {
    width: 100%;
    margin-bottom: 3px;
    height: 70px;
    font-size: 24pt;
}

.botonEnterMonedero {
    margin-top: 3px;
    width: 100%;
    height: 140px;
    vertical-align: bottom;
}

.seccionMonedero {
    width: 75%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}

.seccion2Monedero {
    width: 25%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
}


/* -------------------------------------------------------------------------- */


/*                            A N I M A C I O N E S                           */


/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */


/*                                   FADE IN                                  */


/* -------------------------------------------------------------------------- */

.fade-in-image {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* -------------------------------------------------------------------------- */


/*                                   FADE OUT                                 */


/* -------------------------------------------------------------------------- */

.fade-out-image {
    animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    -moz-animation: fadeOut 2s;
    -o-animation: fadeOut 2s;
    -ms-animation: fadeOut 2s;
    opacity: 0 !important;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* -------------------------------------------------------------------------- */


/*                              TEXTO PARPADEANTE                             */


/* -------------------------------------------------------------------------- */

.parpadea {
    animation-name: parpadeo;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: parpadeo;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes parpadeo {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes parpadeo {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}


/* -------------------------------------------------------------------------- */


/*                             COLOCAR DESDE ABAJO                            */


/* -------------------------------------------------------------------------- */

.movimientoVertical {
    /* animation: MoveUpDown 4s linear infinite; */
    animation: MoveUp var(--tiempo);
    position: absolute;
    top: var(--posicionFinal);
}

@keyframes MoveUp {
    0% {
        /* top: 100%; */
        top: var(--posicionInicial);
    }
    /* 50% {
        top: 50%;
    } */
    100% {
        top: var(--posicionFinal);
    }
}


/* -------------------------------------------------------------------------- */


/*                                   GALERÍA                                  */


/* -------------------------------------------------------------------------- */

.galeria {
    top: 0px;
    position: fixed;
    margin: 4rem auto;
    border: 1px solid #aaa;
    max-height: 100%;
    width: 100%;
    background: #f1f2f3;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
}

.galeria::-webkit-scrollbar {
    -webkit-appearance: none;
}

.galeria::-webkit-scrollbar:vertical {
    width: 30px;
}

.galeria::-webkit-scrollbar-button:increment,
.galeria::-webkit-scrollbar-button {
    display: none;
}

.galeria::-webkit-scrollbar:horizontal {
    height: 10px;
}

.galeria::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.galeria::-webkit-scrollbar-track {
    border-radius: 10px;
}




/* -------------------------------------------------------------------------- */
/*                               TEXTO VERTICAL                               */
/* -------------------------------------------------------------------------- */

.textoVertical {
    writing-mode: vertical-rl; /* Esto establece el modo de escritura vertical de derecha a izquierda */
    /* Esto asegura que el texto esté orientado hacia arriba */
    /* text-orientation: upright;  */
    /* Esto invierte el texto para que se muestre de abajo a arriba */
    transform: rotate(180deg); 
    white-space: nowrap; /* Esto asegura que el texto no se desborde horizontalmente */
  }

  .negrita {
    font-weight: bold;
  }